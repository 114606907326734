import { Button } from '@farewill/ui'
import { ReactElement } from 'react'
import { ButtonProps, ButtonType } from '@farewill/ui/components/Button/types'
import PATHS from 'lib/navigation/paths'
import { Pages } from 'lib/routing/types'

import { PRODUCTS_MAP, PRODUCTS } from 'lib/products/constants'
import {
  ADDITIONAL_SERVICES_PDF_URL,
  SOLVENCY_REPORT_PDF_URL,
  STANDARDISED_PRICING_LIST_PDF_URL,
  WILLS_SIGN_UP_PATH,
} from 'config'

export enum NAVIGATION_LINK_TYPES {
  PRODUCT = 'product',
  EXTERNAL = 'external',
  ACCOUNT = 'account',
}

export type NavigationLink = {
  title: string
  path: string
  type?: NAVIGATION_LINK_TYPES
  cta?: boolean
  rightAlign?: boolean
  partiallyActive?: boolean
  buttonTag?: ReactElement | ButtonType<ButtonProps>
  showOnlyOnPaths?: Array<Pages['path']>
}

export interface ProductLinks {
  id: PRODUCTS
  title: string
  path: string
  pages?: Array<NavigationLink>
}

export const BLOG_CATEGORY_PATH = `${PATHS.GENERAL.CONTENT_HUB}/change-the-world`

const WILLS_LINKS: ProductLinks = {
  id: PRODUCTS.WILLS,
  title: PRODUCTS_MAP[PRODUCTS.WILLS].title,
  path: PATHS.WILLS.OVERVIEW,
  pages: [
    {
      title: 'Online wills',
      path: PATHS.WILLS.OVERVIEW,
    },
    {
      title: 'Telephone wills',
      path: PATHS.WILLS.TELEPHONE,
    },
    {
      title: 'Pricing',
      path: PATHS.WILLS.PRICING,
    },
    {
      title: 'Free guide',
      path: `${PATHS.GENERAL.CONTENT_HUB}/how-to-make-a-will`,
    },
    {
      title: 'Log in',
      path: '/log-in',
      type: NAVIGATION_LINK_TYPES.PRODUCT,
      rightAlign: true,
    },
    {
      title: 'Sign up',
      path: WILLS_SIGN_UP_PATH,
      type: NAVIGATION_LINK_TYPES.PRODUCT,
      cta: true,
      rightAlign: true,
    },
  ],
}

const PROBATE_LINKS: ProductLinks = {
  id: PRODUCTS.PROBATE,
  title: PRODUCTS_MAP[PRODUCTS.PROBATE].title,
  path: PATHS.PROBATE.OVERVIEW,
}

const FUNERALS_PAGES = [
  {
    title: 'Arrange now',
    path: PATHS.CREMATION.DIRECT,
  },
  {
    title: 'Plan ahead',
    path: PATHS.CREMATION.FUNERAL_PLANS,
  },
  {
    title: 'Help and guidance',
    path: `${PATHS.GENERAL.CONTENT_HUB}/how-to-arrange-a-cremation`,
  },
  {
    title: 'Get your free guide',
    path: '/funerals/get-a-guide',
    rightAlign: true,
    cta: true,
    buttonTag: Button.BorderedDark,
    showOnlyOnPaths: [PATHS.CREMATION.FUNERAL_PLANS, PATHS.CREMATION.COMPARE],
  },
]

const CREMATION_LINKS: ProductLinks = {
  id: PRODUCTS.FUNERAL,
  title: PRODUCTS_MAP[PRODUCTS.FUNERAL].title,
  path: PATHS.CREMATION.FUNERAL_OVERVIEW,
  pages: FUNERALS_PAGES,
}

export const FUNERAL_PLANS_LINKS: ProductLinks = {
  id: PRODUCTS.FUNERAL_PLAN,
  title: PRODUCTS_MAP[PRODUCTS.FUNERAL_PLAN].title,
  path: PATHS.CREMATION.FUNERAL_PLANS,
  pages: FUNERALS_PAGES,
}

export const PRODUCT_LINKS_MAP = {
  [PRODUCTS.WILLS]: WILLS_LINKS,
  [PRODUCTS.TELEPHONE_WILLS]: WILLS_LINKS,
  [PRODUCTS.PROBATE]: PROBATE_LINKS,
  [PRODUCTS.FUNERAL]: CREMATION_LINKS,
  [PRODUCTS.FUNERAL_PLAN]: FUNERAL_PLANS_LINKS,
  // ExptCostCompareFuneralPlansLP - Remove after experiment is done
  [PRODUCTS.FUNERAL_PLAN_EXPERIMENT]: FUNERAL_PLANS_LINKS,
}

export const PRODUCT_LINKS: ProductLinks[] = [
  WILLS_LINKS,
  PROBATE_LINKS,
  CREMATION_LINKS,
]

export const FOOTER_LINKS = [
  {
    title: 'About us',
    path: PATHS.GENERAL.ABOUT,
  },
  {
    title: 'Blog',
    path: BLOG_CATEGORY_PATH,
  },
  {
    title: 'Guides',
    path: PATHS.GENERAL.CONTENT_HUB,
  },
  {
    title: 'Press',
    path: PATHS.GENERAL.PRESS,
  },
  {
    title: 'Careers',
    path: PATHS.GENERAL.CAREERS,
  },
  {
    title: 'Security',
    path: PATHS.GENERAL.SECURITY,
  },
  {
    title: 'Charities',
    path: PATHS.GENERAL.CHARITIES,
  },
  {
    title: 'What to do when someone dies',
    path: PATHS.GENERAL.WHAT_TO_DO_WHEN,
  },
].sort((first, second) => (first.title > second.title ? 1 : -1))

export const ADDITIONAL_FOOTER_LINKS_FUNERALS = [
  {
    title: 'Standardised Price List',
    path: STANDARDISED_PRICING_LIST_PDF_URL,
    type: NAVIGATION_LINK_TYPES.EXTERNAL,
  },
  {
    title: 'Additional Services',
    path: ADDITIONAL_SERVICES_PDF_URL,
    type: NAVIGATION_LINK_TYPES.EXTERNAL,
  },
  {
    title: 'Solvency Report',
    path: SOLVENCY_REPORT_PDF_URL,
    type: NAVIGATION_LINK_TYPES.EXTERNAL,
  },
]

export const LEGAL_LINKS: Array<NavigationLink> = [
  {
    title: 'Terms',
    path: PATHS.GENERAL.TERMS,
  },
  {
    title: 'Privacy Policy',
    path: PATHS.GENERAL.PRIVACY,
  },
  {
    title: 'Cookie Policy',
    path: PATHS.GENERAL.COOKIES,
  },
]
