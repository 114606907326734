import qs from 'qs'

import type { PreselectedServiceValues } from 'lib/url/wills/constants'

export const getQueryString = ({
  preselectedService,
  partnerCampaignReferral,
  telephoneWillsEnabled,
  code,
}: {
  preselectedService?: Extract<PreselectedServiceValues, 'online' | 'telephone'>
  partnerCampaignReferral: boolean
  telephoneWillsEnabled?: boolean
  code?: string
}): string => {
  const queryObject = {
    preselected_service: preselectedService,
    ...(partnerCampaignReferral && {
      partner_campaign_referral: true,
      telephone_wills_enabled: !!telephoneWillsEnabled,
    }),
    ...(code && { code }),
  }

  return qs.stringify(queryObject, {
    addQueryPrefix: true,
  })
}
