import React, { useEffect } from 'react'
import { createGlobalStyle, css } from 'styled-components'
import * as Sentry from '@sentry/gatsby'

import {
  COOKIE_BANNER_ENABLED,
  FRONT_APP_CHAT_ID,
  FUNERAL_PLANS_FRONT_APP_CHAT_ID,
} from 'config'

import { loadThirdPartyScript } from 'lib/thirdPartyScripts/helpers'

declare global {
  interface Window {
    FrontChat: (
      action: string,
      options?: {
        chatId: string
        useDefaultLauncher?: boolean
      }
    ) => void
  }
}

const LiveChatGlobalStyles = createGlobalStyle<{
  hasCookieBanner: boolean
}>`
  #front-chat-container > div > div > div {
    margin-bottom: 0 !important;

    ${({ hasCookieBanner }) =>
      !COOKIE_BANNER_ENABLED &&
      hasCookieBanner &&
      css`
        margin-bottom: 80px !important;
      `}
  }
`

interface Props {
  hasCookieBanner?: boolean
  funeralPlansChat?: boolean
}

const LiveChat = ({
  hasCookieBanner = false,
  funeralPlansChat = false,
}: Props): React.ReactElement => {
  const chatId = funeralPlansChat
    ? FUNERAL_PLANS_FRONT_APP_CHAT_ID
    : FRONT_APP_CHAT_ID
  useEffect(() => {
    const loadLiveChat = async () => {
      try {
        await loadThirdPartyScript({
          src: 'https://chat-assets.frontapp.com/v1/chat.bundle.js',
          id: 'frontChat',
        })

        window.FrontChat('init', {
          chatId,
          useDefaultLauncher: true,
        })
      } catch (error) {
        Sentry.captureException(error)
      }
    }

    // if id defined load and init on mount
    if (chatId) loadLiveChat()

    // if loaded shutdown on dismount
    return () => {
      if (window.FrontChat) window.FrontChat('shutdown')
    }
  }, [])

  return <LiveChatGlobalStyles hasCookieBanner={hasCookieBanner} />
}

export default LiveChat
