import React from 'react'

import { PRODUCT_LINKS } from 'lib/navigation/constants'

import MenuLink from './MenuLink'

const AllProductsSection = () => (
  <ul>
    {PRODUCT_LINKS.map((product) => (
      <li key={product.title}>
        <MenuLink to={product.path}>{product.title}</MenuLink>
      </li>
    ))}
  </ul>
)

export default AllProductsSection
