import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import { GTR, FONT } from '@farewill/ui/tokens'
import { Grid, P, Wrapper } from '@farewill/ui'
import { screenMin } from '@farewill/ui/helpers/responsive'
import { SpanColumns } from '@farewill/ui/components/Grid/types'

import PreventOrphan from 'components/PreventOrphan'
import { LEGAL_LINKS, PRODUCT_LINKS_MAP } from 'lib/navigation/constants'
import { PRODUCTS_MAP, PRODUCTS } from 'lib/products/constants'
import { FUNERAL_PLANS_DISCLOSURE_STATEMENT } from 'views/cremation/shared/constants'

import { GENERAL_COPY, WILLS_COPY } from '../constants'
import { isWillsPage } from './helpers'

const FooterLegals = styled(Grid.Item)`
  font-size: ${FONT.SIZE.XS};
`

const FooterLegalLinkWrapper = styled(Wrapper)`
  margin: ${GTR.XS} ${GTR.S} 0 0;
  font-size: ${FONT.SIZE.S};

  &:last-child {
    margin-right: 0;
  }

  ${screenMin.l`
    margin: 0 ${GTR.S} 0 0;
    display: inline-block;
  `}
`

const StyledP = styled(P)`
  font-size: ${FONT.SIZE.XS};
`

const funeralPlansDisclosure = FUNERAL_PLANS_DISCLOSURE_STATEMENT

interface FooterLegalSectionProps {
  showFwlsDisclaimer?: boolean
  product?: keyof typeof PRODUCT_LINKS_MAP | null
}

const FooterLegalSection = ({
  showFwlsDisclaimer = false,
  product,
}: FooterLegalSectionProps): React.ReactElement => {
  const useFarewillFuneralsFooter =
    product && [PRODUCTS.FUNERAL_PLAN, PRODUCTS.FUNERAL].includes(product)
  const useWillsFooter = isWillsPage(product)
  const fwlsDisclaimerCopy = product
    ? PRODUCTS_MAP[product].fwlsDisclaimerCopy
    : null
  const useFwlsFooter = showFwlsDisclaimer && fwlsDisclaimerCopy
  const displayedBusinessName = useFarewillFuneralsFooter
    ? 'Farewill Funerals Ltd'
    : 'Farewill Ltd'

  let linksSpanFromL: SpanColumns = 6
  let copy = GENERAL_COPY
  let copySpanFromM = 8
  let copySpanFromL = 6
  let copyStartColumnFromM = 5
  let copyStartColumnFromL = 7

  if (useFarewillFuneralsFooter) {
    linksSpanFromL = 4
    copy = funeralPlansDisclosure
    copySpanFromM = 9
    copySpanFromL = 8
    copyStartColumnFromM = 4
    copyStartColumnFromL = 5
  } else if (useFwlsFooter) {
    linksSpanFromL = 4
    copy = fwlsDisclaimerCopy
    copySpanFromL = 8
    copyStartColumnFromL = 5
  } else if (useWillsFooter) {
    linksSpanFromL = 4
    copy = WILLS_COPY
    copySpanFromL = 8
    copyStartColumnFromL = 5
  }

  return (
    <>
      <Grid.Item startColumn={1} spanFromM={3} spanFromL={linksSpanFromL}>
        {LEGAL_LINKS.map((link) => (
          <FooterLegalLinkWrapper key={link.title}>
            <Link key={link.title} to={link.path}>
              <PreventOrphan>{link.title}</PreventOrphan>
            </Link>
          </FooterLegalLinkWrapper>
        ))}
      </Grid.Item>

      <FooterLegals
        startColumnFromM={copyStartColumnFromM}
        startColumnFromL={copyStartColumnFromL}
        spanFromM={copySpanFromM}
        spanFromL={copySpanFromL}
      >
        <StyledP>
          © {displayedBusinessName} • {copy}
        </StyledP>
      </FooterLegals>
    </>
  )
}

export default FooterLegalSection
