import PropTypes from 'prop-types'

import useWindowSize from 'lib/ui/useWindowSize'

const BreakpointSwitch = ({
  breakAt,
  aboveComponent,
  belowComponent,
  showLowerByDefault,
}) => {
  const { width: windowWidth } = useWindowSize()

  if (windowWidth === null)
    return showLowerByDefault ? belowComponent : aboveComponent

  return windowWidth < breakAt ? belowComponent : aboveComponent
}

BreakpointSwitch.propTypes = {
  breakAt: PropTypes.number.isRequired,
  aboveComponent: PropTypes.node,
  belowComponent: PropTypes.node,
  showLowerByDefault: PropTypes.bool,
}

BreakpointSwitch.defaultProps = {
  showLowerByDefault: false,
  aboveComponent: null,
  belowComponent: null,
}

export default BreakpointSwitch
