const NOINDEX_TAG = {
  name: 'robots',
  content: 'noindex,nofollow',
}

export default (meta, releaseEnv) => {
  if (releaseEnv === 'production') {
    return meta
  }

  return meta
    .filter((tag) => !(tag.name && tag.name === 'robots'))
    .concat(NOINDEX_TAG)
}
