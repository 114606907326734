import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledNoWrap = styled.span`
  white-space: nowrap;
`
/**
 * Use this component to define the minimum limit of words in last line of multiline text.
 */
const PreventOrphan = ({ children, minimumWords }) => {
  const text = Array.isArray(children) ? children.join('') : children
  if (text) {
    const words = text.split(' ')

    if (words.length < minimumWords) {
      return children
    }

    const cutPoint = words.length - minimumWords
    const start = words.slice(0, cutPoint).join(' ')
    const end = words.slice(cutPoint).join(' ')

    return (
      <>
        {start} {}
        <StyledNoWrap>{end}</StyledNoWrap>
      </>
    )
  }

  return null
}

PreventOrphan.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
  minimumWords: PropTypes.number,
}

PreventOrphan.defaultProps = {
  minimumWords: 2,
}

export default PreventOrphan
