import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Wrapper, Image } from '@farewill/ui'
import { COLOR, GTR } from '@farewill/ui/tokens'
import { screenMin } from '@farewill/ui/helpers/responsive'

import ExternalLink from 'components/ExternalLink'
import TelephoneNumber from 'components/TelephoneNumber'
import { PRODUCTS, PRODUCTS_MAP } from 'lib/products/constants'

type HeaderMacmillanProps = {
  telephoneNumber?: string
}

/**
 * The `FAREWILL_URL` is hardcoded as the `ROOT_URL` config variable will be
 * set to the Macmillan subdomain URL, and we explicitly want to link to the
 * non-Macmillan-cobranded Farewill home page.
 */
const FAREWILL_URL = 'https://farewill.com'
const MACMILLAN_URL =
  'https://www.macmillan.org.uk/donate/gifts-in-wills/free-online-will-service'

const StyledWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  ${screenMin.l`
    border-bottom: solid 1px ${COLOR.GREY.LIGHT};
  `}
`

const StyledLockup = styled(Wrapper)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 18px;

  ${screenMin.m`
    gap: 20px;
  `}
`

const StyledTelephoneNumber = styled(TelephoneNumber)`
  display: none;
  margin-right: ${GTR.XXL};
  padding-top: ${GTR.S};

  ${screenMin.m`
    display: block;
  `}
`

const HeaderMacmillan = ({
  telephoneNumber,
}: HeaderMacmillanProps): React.ReactElement => (
  <StyledWrapper tag="header" background={COLOR.WHITE}>
    <StyledLockup>
      <ExternalLink href={MACMILLAN_URL}>
        <Image
          path="cobranding-partner-logos/macmillan-white-on-green"
          ext="svg"
          width={150}
          widthFromM={192}
          alt="Macmillan Cancer Support"
        />
      </ExternalLink>

      <Link to={FAREWILL_URL}>
        <Image
          path="internal-logos/logo-farewill"
          width={80}
          widthFromM={90}
          alt="Farewill"
        />
      </Link>
    </StyledLockup>

    <StyledTelephoneNumber
      telephoneNumber={
        telephoneNumber || PRODUCTS_MAP[PRODUCTS.WILLS].telephoneNumber
      }
    />
  </StyledWrapper>
)

export default HeaderMacmillan
