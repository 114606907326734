import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Grid, Divider, Wrapper, P, BulletSeparator } from '@farewill/ui'
import { BREAKPOINT, COLOR, FONT, GTR } from '@farewill/ui/tokens'
import { screenMin } from '@farewill/ui/helpers/responsive'

import LogoText from 'assets/logo-text.svg'
import { FAREWILL_OFFICE_ADDRESS } from 'config'
import BreakpointSwitch from 'components/BreakpointSwitch'
import PreventOrphan from 'components/PreventOrphan'
import {
  ADDITIONAL_FOOTER_LINKS_FUNERALS,
  LEGAL_LINKS,
  PRODUCT_LINKS_MAP,
} from 'lib/navigation/constants'
import { PRODUCTS_MAP } from 'lib/products/constants'

import { isFuneralsPage, isWillsPage } from '../Full/helpers'
import { GENERAL_COPY, WILLS_COPY } from '../constants'
import FarewillFuneralsFooter from './FarewillFuneralsFooter'

const StyledDisclaimer = styled(P)`
  font-size: ${FONT.SIZE.XS};

  ${screenMin.m`
    text-align: right;
  `}
`

const StyledWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  color: ${COLOR.BLACK};

  ${screenMin.l`
    flex-direction: row;
    align-items: center;
  `}
`

const StyledBulletSeparator = styled(BulletSeparator)`
  font-size: ${FONT.SIZE.S};
  flex: 1;
  margin: ${GTR.S} 0;

  ${screenMin.l`
    margin: 0;
  `}
`

const FooterLegalLinkWrapper = styled(Wrapper)`
  margin: ${GTR.XS} 0;
  font-size: ${FONT.SIZE.S};

  &:last-child {
    margin-right: 0;
  }

  ${screenMin.l`
    margin: ${GTR.XS};
    display: inline-block;
  `}
`

const FooterLegalLink = styled(Link)`
  text-decoration: none;
`

const FooterCompact = ({
  showFwlsDisclaimer = false,
  selectedProduct,
}: {
  showFwlsDisclaimer: boolean
  selectedProduct?: keyof typeof PRODUCT_LINKS_MAP | null
}): React.ReactElement => {
  const useFarewillFuneralsFooter = isFuneralsPage(selectedProduct)
  const useWillsFooter = isWillsPage(selectedProduct)
  const legalLinks = useFarewillFuneralsFooter
    ? [...ADDITIONAL_FOOTER_LINKS_FUNERALS, ...LEGAL_LINKS]
    : LEGAL_LINKS

  const copy = useWillsFooter ? WILLS_COPY : GENERAL_COPY

  const fwlsDisclaimerCopy = selectedProduct
    ? PRODUCTS_MAP[selectedProduct].fwlsDisclaimerCopy
    : null

  const displayedBusinessName = useFarewillFuneralsFooter
    ? 'Farewill Funerals'
    : 'Farewill'
  return (
    <Wrapper background={COLOR.WHITE} tag="footer">
      <Wrapper container containerPaddingTop="XL" containerPaddingBottom="XL">
        <Grid>
          {useFarewillFuneralsFooter ? (
            <FarewillFuneralsFooter />
          ) : (
            <>
              <Grid.Item spanFromM={4} spanFromL={5} spanFromXL={6}>
                <img src={LogoText} width="90px" height="39px" alt="Farewill" />
              </Grid.Item>
              <Grid.Item spanFromM={8} spanFromL={7} spanFromXL={6}>
                <StyledDisclaimer>
                  <PreventOrphan>
                    {showFwlsDisclaimer ? (fwlsDisclaimerCopy as string) : copy}
                  </PreventOrphan>
                </StyledDisclaimer>
              </Grid.Item>
            </>
          )}
        </Grid>
        <Divider margin={['L', 0, 'M']} background={COLOR.GREY.LIGHT} />
        <StyledWrapper>
          <BreakpointSwitch
            breakAt={BREAKPOINT.S}
            aboveComponent={
              <StyledBulletSeparator>
                <span>&copy; {displayedBusinessName} Ltd </span>
                <span>
                  <PreventOrphan
                    minimumWords={FAREWILL_OFFICE_ADDRESS.split(' ').length}
                  >
                    {FAREWILL_OFFICE_ADDRESS}
                  </PreventOrphan>
                </span>
              </StyledBulletSeparator>
            }
            belowComponent={
              <>
                <P size="S" margin={['XS', 0]}>
                  &copy; {displayedBusinessName} Ltd
                </P>
                <P size="S">{FAREWILL_OFFICE_ADDRESS}</P>
              </>
            }
          />

          <Wrapper margin={[0, 0, 'XS', 0]}>
            {legalLinks.map((link) => (
              <FooterLegalLinkWrapper key={link.title}>
                <FooterLegalLink to={link.path}>
                  <PreventOrphan>{link.title}</PreventOrphan>
                </FooterLegalLink>
              </FooterLegalLinkWrapper>
            ))}
          </Wrapper>
        </StyledWrapper>
      </Wrapper>
    </Wrapper>
  )
}

export default FooterCompact
