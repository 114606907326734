import React from 'react'

import { FAREWILL_ACCOUNT_APP_URL } from 'config'

type AccountAppLinkProps = {
  className?: string
  to: string
  children: React.ReactNode
}

const AccountAppLink = ({
  className,
  to,
  children,
}: AccountAppLinkProps): React.ReactElement => (
  <a className={className} href={`${FAREWILL_ACCOUNT_APP_URL}${to}`}>
    {children}
  </a>
)

export default AccountAppLink
