import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { P, PhoneSolidIcon, Wrapper } from '@farewill/ui'
import { BORDER, COLOR, FONT } from '@farewill/ui/tokens'
import { screenMin } from '@farewill/ui/helpers/responsive'
import TelephoneNumber from 'components/TelephoneNumber'

const StyledTelephoneNumber = styled(TelephoneNumber)`
  font-size: ${FONT.SIZE.S};
  font-weight: ${FONT.WEIGHT.MEDIUM};
`

const StyledWrapper = styled(Wrapper)`
  box-shadow: ${BORDER.SHADOW.M};
  display: grid;
  grid-template-columns: 1fr 45px 1fr;
  align-items: center;
  justify-content: center;
  padding: 6px 0;

  & > p {
    justify-self: flex-end;
  }

  & > svg {
    justify-self: center;
  }

  & > ${StyledTelephoneNumber} {
    justify-self: flex-start;
  }

  ${screenMin.l`
    display: none;
  `}
`

const CallUsHeaderBanner = ({
  telephoneNumber,
  className,
}: {
  telephoneNumber: string
  className?: string
}): ReactElement => (
  <StyledWrapper className={className} background={COLOR.BACKGROUND.FOG}>
    <P size="S" color={COLOR.BLACK} margin={0} strong>
      Call us today
    </P>
    <PhoneSolidIcon
      background={COLOR.ACCENT.PRIMARY}
      color={COLOR.BLACK}
      size="S"
    />
    <StyledTelephoneNumber showIcon={false} telephoneNumber={telephoneNumber} />
  </StyledWrapper>
)

export default CallUsHeaderBanner
