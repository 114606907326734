import React, { ReactElement, useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { screenMax, screenMin } from '@farewill/ui/helpers/responsive'
import { COLOR, GTR } from '@farewill/ui/tokens'

import Modal from 'components/Modal'
import { LIVE_PRODUCTS } from 'lib/products/constants'
import useScrollPosition from 'lib/ui/useScrollPosition'

import PrimaryNavigation, { Styles } from './PrimaryNavigation'
import Menu from './Menu'
import CallUsHeaderBanner from './CallUsHeaderBanner'

const StyledPrimaryNavigation = styled(PrimaryNavigation)<{
  isMenuOpen: boolean
  color: string
  mobileColor: string
}>`
  ${({ isMenuOpen }) =>
    isMenuOpen &&
    `
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 110;
  `}

  ${({ isMenuOpen, color }) =>
    color &&
    !isMenuOpen &&
    `
    background-color: ${color};

    ${
      color !== COLOR.WHITE &&
      `
      border-bottom-color: ${color};
    `
    }
  `}



  ${({ isMenuOpen, mobileColor }) =>
    mobileColor &&
    !isMenuOpen &&
    screenMax.m`
    background-color: ${mobileColor};

    ${`
      border-bottom-color: ${mobileColor};
    `}
  `}
`

const StyledMenu = styled(Menu)`
  ${screenMin.l`
    display: none;
  `}
`

const StyledModal = styled(Modal)`
  ${screenMin.l`
    display: none;
  `}
`

const StyledCallUsHeaderBanner = styled.div<{
  $isFixed: boolean
  $primaryNavigationHeight: string | null
}>`
  ${({ $isFixed, $primaryNavigationHeight }) =>
    $isFixed
      ? css`
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          z-index: 100;
        `
      : `
      position: absolute;
      top: ${$primaryNavigationHeight};
      left: 0;
      right: 0;
      z-index: 100;
    `}
`

const StyledHeader = styled.header<{ $sticky: boolean }>`
  ${({ $sticky }) =>
    $sticky &&
    css`
      ${screenMax.m`
        margin-bottom: ${GTR.L};
      `}
      ${screenMin.l`
        position: sticky;
        top: 0;
        z-index: 100;
      `}
    `}
`

export type HeaderProps = {
  selectedProduct?: (typeof LIVE_PRODUCTS)[number]
  mobileColor: string
  color: string
  showLoginLink?: boolean
  telephoneNumber?: string
  headerStyle?: Styles
  headerFarewillLogoLink?: string
}

const HeaderFull = ({
  selectedProduct,
  mobileColor,
  color,
  showLoginLink,
  telephoneNumber = '',
  headerFarewillLogoLink,
  headerStyle,
}: HeaderProps): ReactElement => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [primaryNavigationHeight, setPrimaryNavigationHeight] = useState<
    string | null
  >(null)
  const primaryNavigationWrapper = useRef<HTMLDivElement | null>(null)
  const callUsHeaderBannerWrapper = useRef<HTMLDivElement | null>(null)
  const scrollPosition = useScrollPosition()
  const [fixedCallUsBanner, setFixedCallUsBanner] = useState(false)

  const onMenuButtonClick = () => {
    if (!primaryNavigationWrapper.current) {
      return
    }

    const ref = primaryNavigationWrapper.current

    setIsMenuOpen((prevState) => !prevState)

    if (primaryNavigationHeight) {
      return
    }

    setPrimaryNavigationHeight(`${ref.clientHeight}px`)
  }

  useEffect(() => {
    if (primaryNavigationWrapper.current && primaryNavigationHeight) {
      primaryNavigationWrapper.current.style.height = primaryNavigationHeight
    }
  }, [primaryNavigationHeight])

  useEffect(() => {
    if (
      !primaryNavigationWrapper.current?.clientHeight ||
      !callUsHeaderBannerWrapper.current?.offsetHeight
    )
      return

    const height =
      primaryNavigationWrapper.current?.clientHeight -
      callUsHeaderBannerWrapper.current?.clientHeight
    if (scrollPosition > height && !fixedCallUsBanner) {
      setFixedCallUsBanner(true)
    } else if (scrollPosition <= height && fixedCallUsBanner) {
      setFixedCallUsBanner(false)
    }
  }, [scrollPosition])

  return (
    <StyledHeader $sticky={headerStyle === 'funeralPlanSticky'}>
      <div ref={primaryNavigationWrapper}>
        <StyledPrimaryNavigation
          isMenuOpen={isMenuOpen}
          onMenuButtonClick={onMenuButtonClick}
          mobileColor={mobileColor}
          color={color}
          selectedProduct={selectedProduct}
          showLoginLink={showLoginLink}
          telephoneNumber={telephoneNumber}
          headerStyle={headerStyle}
          headerFarewillLogoLink={headerFarewillLogoLink}
        />
        {headerStyle === 'funeralPlanSticky' && !isMenuOpen && (
          <StyledCallUsHeaderBanner
            $isFixed={fixedCallUsBanner}
            $primaryNavigationHeight={primaryNavigationHeight}
            ref={callUsHeaderBannerWrapper}
          >
            <CallUsHeaderBanner telephoneNumber={telephoneNumber} />
          </StyledCallUsHeaderBanner>
        )}
      </div>

      {isMenuOpen && (
        <StyledModal
          close={() => setIsMenuOpen(false)}
          offsets={{ top: primaryNavigationHeight }}
        >
          <StyledMenu showLoginLink={showLoginLink} />
        </StyledModal>
      )}
    </StyledHeader>
  )
}

export default HeaderFull
