import React from 'react'
import styled from 'styled-components'
import { COLOR } from '@farewill/ui/tokens'
import { screenMax } from '@farewill/ui/helpers/responsive'

import scrollToElement from 'lib/navigation/scrollToElement'

const StyledLinkWrapper = styled.div<{
  $mobileColor?: string
  $color: string
  $vertical: boolean
}>`
  position: absolute;
  top: -1000px;
  left: 0;
  right: 0;

  z-index: 25;
  text-align: center;

  &:focus-within {
    padding: 20px;
    background: ${({ $color }) => $color};
    top: 0;
    ${({ $vertical }) => $vertical && `bottom: 0;`}
  }

  ${({ $mobileColor }) =>
    $mobileColor &&
    screenMax.m`
    &:focus-within {
      background: ${$mobileColor};
    }
  `}
`

const handleSkipContent = (
  e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  mainContentRef: React.RefObject<HTMLElement>
) => {
  e.preventDefault()
  if (mainContentRef.current) {
    scrollToElement({
      event: e,
      elementId: 'main-content',
      offset: 0,
      ignoreHistory: true,
    })
    mainContentRef.current.setAttribute('tabindex', '-1')
    mainContentRef.current.focus()
  }
}

interface SkipToContentProps {
  children: string
  color?: string
  mobileColor?: string
  mainContentRef: React.RefObject<HTMLElement>
  vertical?: boolean
}

const SkipToContent = ({
  children,
  color = COLOR.WHITE,
  mobileColor,
  mainContentRef,
  vertical = false,
}: SkipToContentProps): React.ReactElement => (
  <StyledLinkWrapper
    $color={color}
    $mobileColor={mobileColor}
    $vertical={vertical}
  >
    <button type="button" onClick={(e) => handleSkipContent(e, mainContentRef)}>
      {children}
    </button>
  </StyledLinkWrapper>
)

export default SkipToContent
