import HeaderCompact from './Compact'
import HeaderFull from './Full'
import HeaderMinimal from './Minimal'
import HeaderMacmillan from './Macmillan'

export const headerTypeMap = {
  compact: HeaderCompact,
  full: HeaderFull,
  minimal: HeaderMinimal,
  macmillan: HeaderMacmillan,
}

export const headerTypes = Object.keys(headerTypeMap) as Array<
  keyof typeof headerTypeMap
>
