import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled, { css, keyframes } from 'styled-components'
import { PhoneSolidIcon } from '@farewill/ui'
import { FONT, GTR, COLOR, BORDER } from '@farewill/ui/tokens'
import { screenMax } from '@farewill/ui/helpers/responsive'

import { trackEvent } from 'lib/tracking'
import formatTelephoneNumber from 'lib/formatting/telephoneNumber'
import { GENERAL_TELEPHONE_NUMBER } from 'config'
import DefaultLayoutContext from 'layouts/context'

const trackTelephoneClick = (telephoneNumber) =>
  trackEvent(
    `Phone number (${formatTelephoneNumber(telephoneNumber)})`,
    'click'
  )

const telephoneRingAnimation = keyframes`
  0% {
    transform: rotate(0);
  }
  20% {
    transform: rotate(25deg);
  }
  40% {
    transform: rotate(-10deg);
  }
  60% {
    transform: rotate(25deg);
  }
  80% {
    transform: rotate(-10deg);
  }
  100% {
    transform: rotate(0);
  }
`

const StyledIcon = styled(PhoneSolidIcon)`
  margin-right: 10px;
  position: relative;

  ${({ large }) => large && `top: 0;`}
`

const StyledTelephoneNumber = styled.a`
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  text-align: center;

  &:focus {
    ${StyledIcon} {
      animation: 0.5s ${telephoneRingAnimation} ease-out 1;
    }
  }

  ${({
    $strong,
    $bold,
    $underline,
    $size,
    $highlighted,
    $highlightColor,
    $visible,
    $hasButtonStyling,
  }) =>
    css`
      ${$strong &&
      `
        color: ${COLOR.BLACK};
        font-weight: ${FONT.WEIGHT.BOLD};
      `}

      ${$bold && `font-weight: ${FONT.WEIGHT.BOLD};`}
      text-decoration: ${$underline ? 'underline' : 'none'};

      &:hover {
        text-decoration: ${$underline ? 'underline' : 'none'};
      }

      ${$size === 'medium' &&
      `
        font-size: ${FONT.SIZE.L};
        font-weight: ${FONT.WEIGHT.MEDIUM};
      `}

      ${$size === 'large' &&
      `
        font-size: ${FONT.SIZE.XL};
        font-weight: ${FONT.WEIGHT.MEDIUM};

        ${screenMax.s`
          font-size: ${FONT.SIZE.L};
        `}
      `}

      ${$highlighted &&
      `
        padding: ${GTR.S};
        border-radius: ${BORDER.RADIUS.S};
        background: ${$highlightColor};

        ${screenMax.s`
          padding: ${GTR.S} ${GTR.XS};
        `}
      `}

      ${!$visible && `visibility: hidden;`}

      ${$hasButtonStyling &&
      `
        background: ${$highlightColor};
        padding: 13px ${GTR.S};
        border-radius: ${BORDER.RADIUS.S};

        ${screenMax.s`
          padding: ${GTR.S} ${GTR.XS};
        `}
      `}
    `}
`

const TelephoneNumber = ({
  className,
  size,
  showIcon,
  iconBackground,
  highlighted,
  highlightColor,
  telephoneNumber: inputTelephoneNumber,
  underline,
  strong,
  bold,
  preventSwapping,
  hasButtonStyling,
  buttonPrefix,
}) => {
  const { loadedTracking, trackedPhoneNumber, usePhoneNumberTracking } =
    useContext(DefaultLayoutContext)

  const telephoneNumber =
    !preventSwapping && usePhoneNumberTracking && trackedPhoneNumber
      ? trackedPhoneNumber
      : inputTelephoneNumber

  const isLarge = size === 'large'
  const formattedTelephoneNumber = formatTelephoneNumber(telephoneNumber)

  return (
    <StyledTelephoneNumber
      className={className}
      href={`tel:${telephoneNumber}`}
      onClick={() => trackTelephoneClick(telephoneNumber)}
      $size={size}
      $highlighted={highlighted}
      $highlightColor={highlightColor}
      rel="nofollow"
      $underline={underline}
      $strong={strong}
      $bold={bold}
      $visible={loadedTracking || !usePhoneNumberTracking}
      $hasButtonStyling={hasButtonStyling}
    >
      {showIcon && (
        <StyledIcon
          background={iconBackground}
          size={isLarge ? 'L' : 'M'}
          large={isLarge}
          inline
        />
      )}

      {buttonPrefix
        ? [buttonPrefix, formattedTelephoneNumber].join(' ')
        : formattedTelephoneNumber}
    </StyledTelephoneNumber>
  )
}

TelephoneNumber.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  showIcon: PropTypes.bool,
  iconBackground: PropTypes.string,
  highlighted: PropTypes.bool,
  highlightColor: PropTypes.string,
  telephoneNumber: PropTypes.string,
  underline: PropTypes.bool,
  strong: PropTypes.bool,
  bold: PropTypes.bool,
  preventSwapping: PropTypes.bool,
  hasButtonStyling: PropTypes.bool,
  buttonPrefix: PropTypes.string,
}

TelephoneNumber.defaultProps = {
  className: '',
  size: 'small',
  showIcon: true,
  highlighted: false,
  iconBackground: undefined,
  highlightColor: COLOR.ACCENT.PRIMARY,
  telephoneNumber: GENERAL_TELEPHONE_NUMBER,
  underline: false,
  strong: true,
  bold: false,
  preventSwapping: false,
  hasButtonStyling: false,
  buttonPrefix: null,
}

export default TelephoneNumber
