import { storageAvailable } from './availability'

export const getSessionStorage = <T>(key: string): T | null => {
  if (storageAvailable('sessionStorage')) {
    const item = sessionStorage.getItem(key)
    return item ? JSON.parse(item) : null
  }
  return null
}

export const setSessionStorage = <T>(key: string, data: T): void | null => {
  if (storageAvailable('sessionStorage')) {
    const existingStoreData = getSessionStorage<T>(key)
    return sessionStorage.setItem(
      key,
      JSON.stringify({ ...existingStoreData, ...data })
    )
  }
  return null
}
