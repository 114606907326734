import { normalizeArray } from 'lib/graphql/normalize'

export const SITEMAP_EXCLUDE = [
  '/referral',
  '/lasting-power-of-attorney/callback',
  '/probate/send-us-a-message',
  '/brighter-funerals/questions',
  '/brighter-funerals/plans',
  '/brighter-funerals/contact/thank-you',
  '/brighter-funerals/contact/plans',
  '/brighter-funerals/contact/plans/thank-you',
  '/landing/brighter-goodbyes',
  '/landing/wills/property-trusts',
  '/landing/wills/write-a-will/',
  '/wills/telephone-wills',
  '/wills/telephone-wills/contact',
  '/previews/*',
  '/previews/*/*',
]

/**
 * If the page's path is on the list, sitemap will ignore
 * 'hideFromSitemap' field and print the page regardless.
 */
export const SITEMAP_EXCEPTIONS = ['/nhs']

/**
 * This query fetches all the data needed to construct the sitemap.
 *
 * The siteUrl metadata is set in gatsby-config and corresponds to the root
 * url of the app.
 *
 * Pages matching the paths above are filtered out of the final sitemap, but
 * as partner pages can use any slug and we don't want to include them in the
 * sitemap, we filter them out here by the page component.
 *
 * If the context field is provided in Gatsby's createPage action arguments its
 * contents will be attached to the graphql page node and can be queried; for
 * pages created based on Contentful data we add a contentLastModified field to
 * the page context, and we use that here to add last modified information to
 * the sitemap.
 */

export const sitemapQuery = `
{
  site {
    siteMetadata {
      siteUrl
    }
  }

  allSitePage {
    edges {
      node {
        path
        context {
          contentLastModified
          hideFromSitemap
        }
      }
    }
  }
}
`

interface pageDataProps {
  url: string
  path: string
  lastmodISO?: string
  hideFromSitemap: boolean
}

interface SitemapProps {
  site: {
    siteMetadata: {
      siteUrl: string
    }
  }
  allSitePage: {
    edges: {
      node: {
        path: string
        context: {
          contentLastModified: string
          hideFromSitemap: boolean
        }
      }
    }[]
  }
}

export const serializeSitemap = ({
  site,
  allSitePage,
}: SitemapProps): pageDataProps[] =>
  normalizeArray(allSitePage)
    .map((page) => {
      const pageData: pageDataProps = {
        url: `${site.siteMetadata.siteUrl}${page.path}`,
        path: page.path,
        lastmodISO: undefined,
        hideFromSitemap: false,
      }

      if (page.context.contentLastModified) {
        pageData.lastmodISO = page.context.contentLastModified
      }

      if (
        page.context.hideFromSitemap &&
        !SITEMAP_EXCEPTIONS.includes(page.path)
      ) {
        pageData.hideFromSitemap = true
      }

      return pageData
    })
    .filter((page) => !page.hideFromSitemap)
