import React, { useState, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Wrapper, P } from '@farewill/ui'
import { COLOR, FONT, GTR, BORDER_RADIUS } from '@farewill/ui/tokens'

import PATHS from 'paths'
import { CookieNames } from 'lib/storage/cookies/constants'
import { getCookie, setCookie } from 'lib/storage/cookies/helpers'

const StyledCookieBanner = styled(Wrapper)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${COLOR.BACKGROUND.FOG};
  border-top: solid 1px ${COLOR.GREY.LIGHT};
  z-index: 120;

  @media print {
    display: none;
  }
`

const StyledWrapper = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StyledText = styled(P)`
  font-size: ${FONT.SIZE.S};
  margin: 0;
`

const StyledButton = styled.button`
  background-color: ${COLOR.GREY.LIGHT};
  padding: ${GTR.XS} ${GTR.S};
  font-size: ${FONT.SIZE.XS};
  font-weight: ${FONT.WEIGHT.MEDIUM};
  line-height: 1.333;
  border-radius: ${BORDER_RADIUS.S};
  margin-left: ${GTR.S};
`

const CookieBannerContent = ({ setCookieBannerShown, ignoreCookie }, ref) => {
  const [showCookieBanner, setShowCookieBanner] = useState(false)

  useLayoutEffect(() => {
    setShowCookieBanner(!getCookie('cookieBannerHidden'))
  }, [])

  const onClose = () => {
    setCookieBannerShown(false)
    setCookie(CookieNames.COOKIE_BANNER_HIDDEN, true)
  }

  if (!ignoreCookie && !showCookieBanner) return null

  return (
    <StyledCookieBanner data-percy-hide>
      <div ref={ref}>
        <StyledWrapper
          container
          containerPaddingTop="XS"
          containerPaddingBottom="XS"
        >
          <StyledText>
            Farewill uses cookies to make the site simpler.{' '}
            <Link to={PATHS.GENERAL.PRIVACY}>Find out more about cookies</Link>
          </StyledText>
          <StyledButton onClick={onClose} aria-label="Close cookie banner">
            Close
          </StyledButton>
        </StyledWrapper>
      </div>
    </StyledCookieBanner>
  )
}

const CookieBanner = React.forwardRef(CookieBannerContent)

CookieBannerContent.propTypes = {
  setCookieBannerShown: PropTypes.func,
  ignoreCookie: PropTypes.bool,
}

CookieBannerContent.defaultProps = {
  setCookieBannerShown: null,
  ignoreCookie: false,
}

export default CookieBanner
