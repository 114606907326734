import { ProductLinks, PRODUCT_LINKS } from './constants'
import { PRODUCTS } from '../products/constants'

export const getTopLevelProductLinks = (
  selectedProduct: PRODUCTS | null
): ProductLinks[] => {
  if (!selectedProduct) {
    return PRODUCT_LINKS
  }

  return PRODUCT_LINKS.filter(({ id }) => id !== selectedProduct)
}
