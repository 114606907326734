import { useEffect } from 'react'

import { CookieNames } from 'lib/storage/cookies/constants'
import {
  getSessionStorage,
  setSessionStorage,
} from 'lib/storage/sessionStorage'
import {
  hasSavedTrackingParams,
  hasSavedFirstTouchTrackingParams,
} from 'lib/tracking/trackingParamsHelpers'
import {
  saveTrackingParams,
  saveTrackingParamsAsync,
  saveWillsReferralParams,
} from 'lib/tracking/trackingParams'
import { FACEBOOK_EVENTS } from 'lib/tracking/constants'
import { trackPartnerCampaignPageVisit, trackFacebookEvent } from 'lib/tracking'
import { isInternalReferral } from 'lib/tracking/helpers'
import { storeGoogleClickId } from 'lib/tracking/googleAdsClickIdentifier'
import { getQueryParameters } from 'lib/url/getQueryParameters'

type sessionCookies = {
  formattedTrackingParamsCookie: boolean
}

type UtmParameter = {
  utmCampaign: string
  utmMedium: string
  utmSource?: string
  utmChannel?: string
}

const { FIRST_TOUCH_TRACKING_COOKIE_NAME } = CookieNames

const dispatchCookiesUpdatedEvent = () => {
  // Don't dispatch this event in production - It is only intended as a hook for
  // testing.
  if (process.env.NEXT_ENV === 'production') {
    return
  }
  window.dispatchEvent(new Event('fwTrackingCookiesUpdated'))
}

const useParameterTracking = (
  utmParameters?: UtmParameter,
  branding?: string
): void => {
  useEffect(() => {
    const cookiesSetInSession: sessionCookies | null = getSessionStorage(
      'cookiesSetInSession'
    )

    if (
      cookiesSetInSession?.formattedTrackingParamsCookie &&
      hasSavedFirstTouchTrackingParams()
    )
      return

    const queryParameters = getQueryParameters()

    setSessionStorage('urlParams', utmParameters || queryParameters)

    storeGoogleClickId({
      gclid: queryParameters.gclid as string,
      gclsrc: queryParameters.gclsrc as string,
    })

    let lastTouchTrackingParams
    const isDirectVisit = !document.referrer

    /**
     * We want to overwrite the cookie if the current session is a visit from
     * the accounts app or wills app with query parameters (utm). This is especially
     * useful for when we utilise utms for tracking memberships cross-sell. Membership
     * buttons on Accounts app and Wills app have utms attached to them, we want the
     * tracking params for last touch to be overwritten with them, if user visits the
     * landing page from these apps, clicking on the membership buttons.
     */
    const isInternalRefferalWithParams =
      isInternalReferral(document.referrer) &&
      Object.keys(queryParameters).length > 0

    const overwriteCookie =
      isInternalRefferalWithParams ||
      !!utmParameters ||
      (!isDirectVisit && !isInternalReferral(document.referrer))

    // If the current session is a visit from our sub-sites with query parameters or
    // to a partner page and is not a direct visit neither a visit from one of our own sub-sites,
    // update the cookie to the current session params.
    // If it is a direct visit but there is no formatted cookie from a previous visit, store
    // a new cookie using the params from the current session.
    if (overwriteCookie || !hasSavedTrackingParams()) {
      lastTouchTrackingParams = saveTrackingParams(utmParameters)
      saveWillsReferralParams({
        branding,
        referrer: lastTouchTrackingParams.utmSource,
        referringPartnerId: lastTouchTrackingParams.partnerId,
        voucher: lastTouchTrackingParams.utmCampaign,
      })
      saveTrackingParamsAsync()

      setSessionStorage('cookiesSetInSession', {
        formattedTrackingParamsCookie: true,
      })
    }

    if (!hasSavedFirstTouchTrackingParams()) {
      const firstTouchTrackingParams = saveTrackingParams(
        utmParameters,
        FIRST_TOUCH_TRACKING_COOKIE_NAME,
        365
      )
      saveTrackingParamsAsync(FIRST_TOUCH_TRACKING_COOKIE_NAME, 365)
      saveWillsReferralParams({
        branding,
        referrer: firstTouchTrackingParams.utmSource,
        referringPartnerId: firstTouchTrackingParams.partnerId,
        voucher: firstTouchTrackingParams.utmCampaign,
      })
    }

    if (lastTouchTrackingParams?.utmChannel === 'charity') {
      trackFacebookEvent(FACEBOOK_EVENTS.CHARITY_PAGE_VISIT)
      trackPartnerCampaignPageVisit(lastTouchTrackingParams)
    }
  }, [])

  // Dispatch an event to let tests know that the cookies have been updated.
  // Performed in a separate useEffect as a simple way to ensure it always fires
  // irrespective of the code path taken within the useEffect that actually
  // updates the cookies (above)
  useEffect(dispatchCookiesUpdatedEvent, [])
}

export default useParameterTracking
