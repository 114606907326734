import React from 'react'
import styled from 'styled-components'
import { FONT } from '@farewill/ui/tokens'
import { Grid, P } from '@farewill/ui'

import LogoText from 'assets/logo-text.svg'
import { FUNERAL_PLANS_DISCLOSURE_STATEMENT } from 'views/cremation/shared/constants'

const StyledFuneralPlansDisclosureStatement = styled(P)`
  font-size: ${FONT.SIZE.XS};
`

const FarewillFuneralsFooter = (): React.ReactElement => (
  <>
    <Grid.Item spanFromM={1} spanFromXL={2}>
      <img src={LogoText} width="90px" height="39px" alt="Farewill" />
    </Grid.Item>
    <Grid.Item
      spanFromM={10}
      spanFromXL={9}
      startColumnFromM={3}
      startColumnFromXL={4}
    >
      <StyledFuneralPlansDisclosureStatement>
        {FUNERAL_PLANS_DISCLOSURE_STATEMENT}
      </StyledFuneralPlansDisclosureStatement>
    </Grid.Item>
  </>
)

export default FarewillFuneralsFooter
