import React from 'react'

import styled from 'styled-components'
import { Grid, Divider, P, Wrapper } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'

import {
  ADDITIONAL_FOOTER_LINKS_FUNERALS,
  FOOTER_LINKS,
  PRODUCT_LINKS_MAP,
} from 'lib/navigation/constants'
import { PRODUCTS_MAP } from 'lib/products/constants'
import { getTopLevelProductLinks } from 'lib/navigation/products'
import TelephoneNumber from 'components/TelephoneNumber'
import { GENERAL_TELEPHONE_NUMBER, FAREWILL_OFFICE_ADDRESS } from 'config'

import FooterIllustration from './FooterIllustration'
import FooterLegalSection from './FooterLegalSection'
import FooterSection from './FooterSection'
import FooterLinks from './FooterLinks'
import { isFuneralsPage } from './helpers'

const StyledFooter = styled.footer`
  background: ${COLOR.WHITE};
  border-top: 1px solid ${COLOR.GREY.LIGHT};

  a {
    text-decoration: none;
    color: ${COLOR.BLACK};
    transition: color 0.15s linear;

    &:focus {
      outline: 1px dashed ${COLOR.ACCENT.SECONDARY};
    }

    &:hover {
      text-decoration: underline;
    }

    &:active {
      color: ${COLOR.GREY.DARK};
    }
  }
`

const StyledEmailWrapper = styled(P)`
  word-break: break-all;
`

const getPhoneNumber = (
  telephoneNumber: string,
  selectedProduct: keyof typeof PRODUCT_LINKS_MAP | null
) => {
  if (telephoneNumber) return telephoneNumber
  if (selectedProduct) {
    return PRODUCTS_MAP[selectedProduct].telephoneNumber
  }
  return GENERAL_TELEPHONE_NUMBER
}

interface FooterFullProps {
  selectedProduct?: keyof typeof PRODUCT_LINKS_MAP | null
  showSelectedProductLinks?: boolean
  telephoneNumber?: string
  showFwlsDisclaimer?: boolean

  email?: string
}

const FooterFull = ({
  selectedProduct = null,
  showSelectedProductLinks = true,
  telephoneNumber = '',
  showFwlsDisclaimer = false,
  email,
}: FooterFullProps): React.ReactElement => {
  const topLevelProductLinks = showSelectedProductLinks
    ? getTopLevelProductLinks(selectedProduct)
    : getTopLevelProductLinks(null)

  const selectedProductLinks =
    showSelectedProductLinks && selectedProduct
      ? PRODUCT_LINKS_MAP[selectedProduct]
      : undefined
  const footerLinks = isFuneralsPage(selectedProduct)
    ? [...FOOTER_LINKS, ...ADDITIONAL_FOOTER_LINKS_FUNERALS]
    : FOOTER_LINKS

  return (
    <StyledFooter>
      <Grid
        tag="nav"
        container
        containerPaddingTop="L"
        containerPaddingBottom="L"
        gap="S"
        gapFromM={['M', 'L']}
        gapFromL={['M', 'XL']}
      >
        <FooterSection spanFromM={3} spanFromL={2} logoTitle title="Farewill">
          <FooterLinks links={footerLinks} />
        </FooterSection>

        {selectedProductLinks?.pages && (
          <FooterSection
            spanFromM={3}
            spanFromL={2}
            title={selectedProductLinks.title}
          >
            <FooterLinks
              links={selectedProductLinks.pages}
              currentPath={selectedProductLinks.path}
            />
          </FooterSection>
        )}

        {selectedProductLinks && (
          <FooterSection spanFromM={3} spanFromL={2} title="Other services">
            <FooterLinks links={topLevelProductLinks} withArrows />
          </FooterSection>
        )}

        {!selectedProductLinks && (
          <FooterSection
            spanFromM={6}
            spanFromL={4}
            title="Our services"
            hideTitleOnS
            hideDividerOnS
          >
            <FooterLinks links={topLevelProductLinks} withArrows big />
          </FooterSection>
        )}

        <FooterSection
          spanFromM={3}
          spanFromL={3}
          title="Contact us"
          hideTitleOnS
        >
          <P
            margin={[0, 0, 'XS']}
            marginFromM={email ? [0, 0, 'XS'] : [0, 0, 'S']}
          >
            <TelephoneNumber
              telephoneNumber={getPhoneNumber(telephoneNumber, selectedProduct)}
              showIcon={false}
            />
          </P>
          {email && (
            <Wrapper margin={[0, 0, 'XS']} marginFromM={[0, 0, 'XS']}>
              <StyledEmailWrapper tag="a" href={`mailto:${email}`} strong>
                {email}
              </StyledEmailWrapper>
            </Wrapper>
          )}
          <P>{FAREWILL_OFFICE_ADDRESS}</P>
        </FooterSection>

        <FooterIllustration />

        <Grid.Item span={12} margin={[0, 0, 'XS']}>
          <Divider color={COLOR.GREY.MEDIUM} />
        </Grid.Item>

        <FooterLegalSection
          product={selectedProduct}
          showFwlsDisclaimer={showFwlsDisclaimer}
        />
      </Grid>
    </StyledFooter>
  )
}

export default FooterFull
