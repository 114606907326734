interface ScrollToElementProps {
  event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  elementId: string
  offset?: number
  ignoreHistory?: boolean
}

const scrollToElement = ({
  event,
  elementId,
  offset = 0,
  ignoreHistory,
}: ScrollToElementProps): void => {
  const element = document.getElementById(elementId)
  if (!element) return

  event.preventDefault()

  // add hash to url
  if (!ignoreHistory) {
    window.history.pushState(null, '', `#${elementId}`)
  }

  let scrollTo = element.offsetTop + offset
  const offsetParent = element.offsetParent as HTMLElement
  if (offsetParent && offsetParent.offsetTop) scrollTo += offsetParent.offsetTop

  const supportsNativeSmoothScroll =
    'scrollBehavior' in document.documentElement.style
  if (supportsNativeSmoothScroll) {
    window.scroll({
      top: scrollTo,
      left: window.scrollX,
      behavior: 'smooth',
    })
  } else {
    window.scroll(window.scrollX, scrollTo)
  }
}

export default scrollToElement
