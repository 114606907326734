import React, { PropsWithChildren, ReactElement } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Button } from '@farewill/ui'
import { COLOR, GTR } from '@farewill/ui/tokens'
import { alpha } from '@farewill/ui/helpers/colors'

import getLinkTag from 'lib/navigation/getLinkTag'

import { ACTIVE_NAVIGATION_LINK_STYLES } from './styles'

const BORDER_HEIGHT = GTR.XXS

const StyledLink = styled(Link)`
  text-decoration: none;
  border-bottom: solid ${BORDER_HEIGHT} transparent;
  position: relative;

  &:hover {
    text-decoration: none;
    border-bottom-color: ${alpha(COLOR.GREY.DARK, 0.15)};
  }

  &:active {
    outline: 0;
    text-decoration: none;
    border-bottom-color: ${alpha(COLOR.GREY.DARK, 0.25)};
  }

  &:focus {
    outline: 0;
    text-decoration: none;
    border-bottom-color: ${alpha(COLOR.GREY.DARK, 0.35)};
  }

  // increase touch area
  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: calc(-1 * ${BORDER_HEIGHT});
    left: calc(-1 * ${GTR.XS});
    right: calc(-1 * ${GTR.XS});
  }
`

type NavigationLinkProps = {
  className?: string
  to: string
  type?: 'product' | 'external' | 'account'
  partiallyActive?: boolean
  getProps?: () => void
  isButton?: boolean
  buttonTag?: React.FC<any>
}

const NavigationLink = ({
  className,
  to,
  type,
  partiallyActive,
  children,
  getProps,
  isButton,
  buttonTag = Button.Secondary,
}: PropsWithChildren<NavigationLinkProps>): ReactElement => {
  const tag = type ? getLinkTag(type) : undefined

  if (isButton) {
    const Component = buttonTag
    return (
      <Component tag={tag} className={className} to={to} href={to} size="S">
        {children}
      </Component>
    )
  }

  return (
    <StyledLink
      as={tag}
      className={className}
      to={to}
      href={to}
      partiallyActive={partiallyActive}
      activeStyle={ACTIVE_NAVIGATION_LINK_STYLES}
      getProps={getProps}
    >
      {children}
    </StyledLink>
  )
}

export default NavigationLink
