export const loadThirdPartyScript = ({
  src,
  id,
}: {
  src: string
  id: string
}): Promise<void> =>
  new Promise((resolve, reject) => {
    const existingScript = document.getElementById('scriptId')

    if (existingScript) {
      resolve()
    } else {
      const script = document.createElement('script')
      script.src = src
      script.id = id
      document.body.appendChild(script)

      script.onload = () => resolve()
      script.onerror = reject
    }
  })
