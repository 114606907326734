import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Wrapper } from '@farewill/ui'
import { GTR, COLOR, FONT } from '@farewill/ui/tokens'
import { screenMin, screenMax } from '@farewill/ui/helpers/responsive'

import { GENERAL_TELEPHONE_NUMBER } from 'config'
import PATHS from 'paths'
import { PRODUCT_LINKS } from 'lib/navigation/constants'
import { LIVE_PRODUCTS, PRODUCTS_MAP } from 'lib/products/constants'
import DropdownButton from 'components/DropdownButton'
import TelephoneNumber from 'components/TelephoneNumber'
import LogoTextSvg from 'assets/logo-text.svg'

import NavigationLink from './NavigationLink'
import { ACTIVE_NAVIGATION_LINK_STYLES } from './styles'

const LOCKUP_PADDING = '14px'

const StyledNavigationWrapper = styled.div`
  background-color: ${COLOR.WHITE};
  color: ${COLOR.BLACK};
  border-bottom: solid 1px ${COLOR.GREY.LIGHT};
`

const StyledWrapper = styled(Wrapper)`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
`

const StyledMenuButton = styled(DropdownButton)`
  line-height: 1;
  color: ${COLOR.BLACK};
  font-weight: ${FONT.WEIGHT.BOLD};
  position: relative;
  padding-right: 0;
  padding-left: 0;

  && {
    margin-top: ${LOCKUP_PADDING};
  }

  // increase touch area
  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: calc(-1 * ${GTR.XS});
    right: calc(-1 * ${GTR.XS});
  }

  ${screenMin.l`
    display: none;
  `}
`

const StyledLockup = styled.div`
  padding: ${LOCKUP_PADDING} 0;
`

const StyledNavigation = styled.nav`
  ${screenMax.m`
    display: none;
  `}
`

const StyledPrimaryNavigation = styled(StyledNavigation)`
  flex: 1;
  margin-left: ${GTR.L};
`

const StyledNavigationList = styled.ul`
  display: flex;
  margin: 0;
  height: 100%;
`

const StyledSecondaryNavigation = styled(StyledNavigationList)`
  ${screenMax.m`
    display: none;
  `}
`

const StyledNavigationItem = styled.li`
  &:not(:last-of-type) {
    margin-right: ${GTR.L};
  }

  height: 100%;
  line-height: 1;
`

const StyledLoginWrapper = styled.li`
  margin-right: ${GTR.L};
  line-height: 1;

  ${screenMax.m`
    display: none;
  `};
`

const StyledNavigationLink = styled(NavigationLink)`
  display: block;
  text-decoration: none;
  height: 100%;
  padding-top: ${GTR.L};
`

const StyledTelephoneNumber = styled(TelephoneNumber)<{ $size: 'L' | 'M' }>`
  padding-top: ${GTR.S};
  margin-top: ${GTR.S};

  ${({ $size }) =>
    $size === 'L' &&
    `
    font-size: ${FONT.SIZE.L};
    font-weight: ${FONT.WEIGHT.MEDIUM};
    padding-top: ${GTR.XS};
    padding-bottom: ${GTR.XS};
    `}

  ${screenMax.m`
    display: none;
  `}
`

export type Styles = 'default' | 'funeralPlanSticky'

type PrimaryNavigationProps = {
  className?: string
  isMenuOpen: boolean
  onMenuButtonClick: () => void
  selectedProduct?: (typeof LIVE_PRODUCTS)[number]
  showLoginLink?: boolean
  telephoneNumber?: string
  headerStyle?: Styles
  headerFarewillLogoLink?: string
}

const PrimaryNavigation = ({
  className,
  isMenuOpen,
  onMenuButtonClick,
  selectedProduct,
  showLoginLink,
  telephoneNumber,
  headerStyle,
  headerFarewillLogoLink,
}: PrimaryNavigationProps): ReactElement => {
  const phoneNumber =
    telephoneNumber ||
    (selectedProduct
      ? PRODUCTS_MAP[selectedProduct]?.telephoneNumber
      : GENERAL_TELEPHONE_NUMBER)

  return (
    <StyledNavigationWrapper className={className}>
      <StyledWrapper
        container
        containerPaddingTop={0}
        containerPaddingBottom={0}
      >
        <StyledLockup>
          <Link to={headerFarewillLogoLink || PATHS.GENERAL.HOME}>
            <img src={LogoTextSvg} alt="Farewill" width="90px" height="39px" />
          </Link>
        </StyledLockup>

        <StyledMenuButton isOpen={isMenuOpen} onClick={onMenuButtonClick}>
          Menu
        </StyledMenuButton>

        <StyledPrimaryNavigation aria-label="primary-navigation">
          <StyledNavigationList>
            {PRODUCT_LINKS.map((product) => (
              <StyledNavigationItem key={product.title}>
                <StyledNavigationLink
                  to={product.path}
                  getProps={() => {
                    if (
                      product.id === selectedProduct ||
                      (product.id === 'WILLS' &&
                        selectedProduct === 'TELEPHONE_WILLS')
                    ) {
                      return { style: ACTIVE_NAVIGATION_LINK_STYLES }
                    }

                    return null
                  }}
                >
                  {product.title}
                </StyledNavigationLink>
              </StyledNavigationItem>
            ))}
          </StyledNavigationList>
        </StyledPrimaryNavigation>

        <StyledNavigation>
          <StyledSecondaryNavigation>
            {headerStyle !== 'funeralPlanSticky' && (
              <>
                <StyledNavigationItem>
                  <StyledNavigationLink to={PATHS.GENERAL.ABOUT}>
                    About us
                  </StyledNavigationLink>
                </StyledNavigationItem>
                <StyledNavigationItem>
                  <StyledNavigationLink
                    to={PATHS.GENERAL.CONTENT_HUB}
                    partiallyActive
                  >
                    Guides
                  </StyledNavigationLink>
                </StyledNavigationItem>
                {showLoginLink && (
                  <StyledLoginWrapper>
                    <StyledNavigationLink type="account" to="/login">
                      Log in
                    </StyledNavigationLink>
                  </StyledLoginWrapper>
                )}
              </>
            )}
            {phoneNumber && (
              <li>
                <StyledTelephoneNumber
                  $size={headerStyle === 'funeralPlanSticky' ? 'L' : 'M'}
                  iconBackground={
                    headerStyle === 'funeralPlanSticky'
                      ? COLOR.ACCENT.PRIMARY
                      : undefined
                  }
                  telephoneNumber={phoneNumber}
                />
              </li>
            )}
          </StyledSecondaryNavigation>
        </StyledNavigation>
      </StyledWrapper>
    </StyledNavigationWrapper>
  )
}

export default PrimaryNavigation
