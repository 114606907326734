import React, { PropsWithChildren, ReactElement } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { FONT } from '@farewill/ui/tokens'

import getLinkTag from 'lib/navigation/getLinkTag'

const StyledMenuLink = styled(Link)`
  font-size: ${FONT.SIZE.L};
  font-weight: ${FONT.WEIGHT.MEDIUM};
  text-decoration: none;
`

const MenuLink = ({
  to,
  type,
  children,
}: PropsWithChildren<{
  to: string
  type?: 'product' | 'external'
}>): ReactElement => (
  <StyledMenuLink
    to={to}
    href={to}
    as={getLinkTag(type)}
    activeStyle={{ textDecoration: 'underline' }}
  >
    {children}
  </StyledMenuLink>
)

export default MenuLink
