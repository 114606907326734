import FooterCompact from './Compact'
import FooterFull from './Full'

export const footerTypeMap = {
  compact: FooterCompact,
  full: FooterFull,
}

export const footerTypes = Object.keys(footerTypeMap) as Array<
  keyof typeof footerTypeMap
>
