import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { ArrowRightIcon } from '@farewill/ui'
import { FONT, GTR } from '@farewill/ui/tokens'
import { screenMax } from '@farewill/ui/helpers/responsive'

import getLinkTag from 'lib/navigation/getLinkTag'

const StyledArrowIcon = styled(ArrowRightIcon)`
  position: absolute;
  right: 0;
  top: 50%;
  transition: transform 0.15s ease-in-out;
  transform: translate(0, -50%);
  transform-origin: right center;
`

const StyledFooterLink = styled(({ className, type, to, href, children }) => {
  const Element = getLinkTag(type)
  return (
    <Element className={className} to={to} href={href}>
      {children}
    </Element>
  )
})`
  display: inline-block;

  ${screenMax.s`
    margin-left: -${GTR.XS};
    padding: ${GTR.XXS} ${GTR.XS};
  `}

  ${({ big }) =>
    big &&
    `
    line-height: 1.25;
    font-size: ${FONT.SIZE.XL};
    font-weight: ${FONT.WEIGHT.MEDIUM};
  `}

  ${({ withArrow }) =>
    withArrow &&
    `
    display: block;
    position: relative;

    &:hover > ${StyledArrowIcon} {
      transform: translate(-${GTR.XXS}, -50%);
    }
  `}
`

const FooterLink = ({ children, to, withArrow, big, type }) => (
  <StyledFooterLink
    type={type}
    to={to}
    href={to}
    withArrow={withArrow}
    big={big}
  >
    {children}
    {withArrow && <StyledArrowIcon size={big ? 'M' : 'S'} />}
  </StyledFooterLink>
)

FooterLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  withArrow: PropTypes.bool,
  big: PropTypes.bool,
  type: PropTypes.string,
}

FooterLink.defaultProps = {
  withArrow: false,
  big: false,
  type: null,
}

export default FooterLink
