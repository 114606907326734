import React, { ReactElement, ReactNode, MouseEvent } from 'react'
import styled from 'styled-components'
import { Button } from '@farewill/ui'
import { COLOR, GTR } from '@farewill/ui/tokens'

const StyledDropdownButton = styled(Button.Plain)`
  display: flex;
  align-items: center;
  padding: 0;
  color: ${COLOR.BLACK};
`

const StyledTitle = styled.span`
  flex: 1;
`

const StyledChevron = styled.div`
  border-color: ${COLOR.BLACK} transparent transparent transparent;
  border-style: solid;
  border-width: 4px 4px 0 4px;
  height: 0;
  width: 0;
`

const StyledIconWrapper = styled.div<{
  isOpen: boolean
}>`
  margin-left: ${GTR.XS};
  transition: transform 0.3s ease-in-out;

  ${({ isOpen }) =>
    isOpen &&
    `
    transform: rotate(-180deg);
  `}
`

interface DropdownButtonProps {
  ariaControls?: string
  ariaExpanded?: boolean
  children: ReactNode
  className?: string
  icon?: ReactNode
  isOpen: boolean
  onClick: (e: MouseEvent<HTMLButtonElement>) => void
  stretch?: boolean
}

const DropdownButton = ({
  ariaControls,
  ariaExpanded,
  children,
  className,
  icon = <StyledChevron />,
  isOpen,
  onClick,
  stretch = false,
}: DropdownButtonProps): ReactElement => (
  <StyledDropdownButton
    className={className}
    onClick={onClick}
    stretch={stretch}
    aria-controls={ariaControls}
    aria-expanded={ariaExpanded}
    type="button"
  >
    <StyledTitle>{children}</StyledTitle>
    <StyledIconWrapper isOpen={isOpen}>{icon}</StyledIconWrapper>
  </StyledDropdownButton>
)

export default DropdownButton
