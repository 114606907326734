import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import PATHS from 'paths'

import AccountAppLink from 'components/AccountAppLink'

const StyledLink = styled(Link)`
  text-decoration: none;
`

const StyledAccountAppLink = styled(AccountAppLink)`
  text-decoration: none;
`

const OtherLinksSection = ({
  showLoginLink,
}: {
  showLoginLink?: boolean
}): ReactElement => (
  <ul>
    <li>
      <StyledLink to={PATHS.GENERAL.ABOUT}>About us</StyledLink>
    </li>
    <li>
      <StyledLink to={PATHS.GENERAL.CONTENT_HUB}>Guides</StyledLink>
    </li>
    {showLoginLink && (
      <li>
        <StyledAccountAppLink to="/login">Log in</StyledAccountAppLink>
      </li>
    )}
  </ul>
)

export default OtherLinksSection
