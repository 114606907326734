import React from 'react'
import styled from 'styled-components'
import { P } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'
import { NavigationLink } from 'lib/navigation/constants'
import FooterLink from './FooterLink'

const StyledFooterLinkWrapper = styled(P)`
  line-height: 1.125;
  margin: ${GTR.XS} 0;
`

const FooterLinks = ({
  links,
  withArrows,
  big,
  currentPath,
}: {
  links: NavigationLink[]
  withArrows?: boolean
  big?: boolean
  currentPath?: string
}): React.ReactElement => (
  <>
    {links.map((link) => {
      const hideLink =
        currentPath &&
        link?.showOnlyOnPaths &&
        !link.showOnlyOnPaths.includes(currentPath)
      return (
        <StyledFooterLinkWrapper key={link.title}>
          {!hideLink && (
            <FooterLink
              to={link.path}
              withArrow={withArrows}
              big={big}
              type={link.type}
            >
              {link.title}
            </FooterLink>
          )}
        </StyledFooterLinkWrapper>
      )
    })}
  </>
)

export default FooterLinks
