import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@farewill/ui'

import FooterSectionTitle from './FooterSectionTitle'

const FooterSection = ({
  logoTitle,
  title,
  children,
  hideTitleOnS,
  hideDividerOnS,
  spanFromM,
  spanFromL,
}) => (
  <>
    <FooterSectionTitle
      logoTitle={logoTitle}
      title={title}
      hideTitleOnS={hideTitleOnS}
      hideDividerOnS={hideDividerOnS}
      spanFromM={spanFromM}
      spanFromL={spanFromL}
    />
    <Grid.Item spanFromM={spanFromM} spanFromL={spanFromL} startRowFromM={2}>
      {children}
    </Grid.Item>
  </>
)

FooterSection.propTypes = {
  logoTitle: PropTypes.bool,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  hideTitleOnS: PropTypes.bool,
  hideDividerOnS: PropTypes.bool,
  spanFromM: PropTypes.number.isRequired,
  spanFromL: PropTypes.number.isRequired,
}

FooterSection.defaultProps = {
  logoTitle: false,
  hideTitleOnS: false,
  hideDividerOnS: false,
}

export default FooterSection
