import { useState, useEffect } from 'react'

type WindowSize = {
  width: number | null
  height: number | null
}

const useWindowSize = (): WindowSize => {
  const [windowSize, setWindowSize] = useState({
    width: null,
    height: null,
  } as WindowSize)

  if (typeof window === 'undefined') return windowSize

  useEffect(() => {
    const handleResize = () =>
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })

    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowSize
}

export default useWindowSize
