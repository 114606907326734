import React, { Component } from 'react'
import { Link } from 'gatsby'

import ProductLink from 'components/ProductLink'
import ExternalLink from 'components/ExternalLink'
import AccountAppLink from 'components/AccountAppLink'

const getLinkTag = (
  type?: 'product' | 'external' | 'account'
): React.FC<any> | typeof Component => {
  switch (type) {
    case 'product':
      return ProductLink
    case 'external':
      return ExternalLink
    case 'account':
      return AccountAppLink
    default:
      return Link
  }
}

export default getLinkTag
