import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Grid, Wrapper, H } from '@farewill/ui'
import { GTR, COLOR } from '@farewill/ui/tokens'
import { screenMax } from '@farewill/ui/helpers/responsive'

import LogoTextSvg from 'assets/logo-text.svg'

const StyledFooterSectionTitle = styled(
  ({ hideTitleOnS, hideDividerOnS, ...props }) => <Grid.Item {...props} /> //eslint-disable-line
)`
  align-self: end;
  padding-bottom: ${GTR.XS};
  border-bottom: 1px solid ${COLOR.GREY.MEDIUM};

  // browser hack for IE11 to stop grid order issues, single column layout
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    flex-basis: 100%;
    margin: ${GTR.L} 0 ${GTR.S};
    padding-bottom: 0;
  }

  ${({ hideTitleOnS, hideDividerOnS }) =>
    hideTitleOnS &&
    (hideDividerOnS
      ? screenMax.s`
    display: none;
  `
      : screenMax.s`
  > * {
    display: none;
  }
`)}
`

const FooterSectionTitle = ({
  logoTitle,
  title,
  spanFromM,
  spanFromL,
  hideTitleOnS,
  hideDividerOnS,
}) => (
  <StyledFooterSectionTitle
    spanFromM={spanFromM}
    spanFromL={spanFromL}
    hideTitleOnS={hideTitleOnS}
    hideDividerOnS={hideDividerOnS}
  >
    {logoTitle ? (
      <Wrapper margin={[0, 0, 'S']} marginFromM={[0, 0, 15]} bumpSpecificity>
        <a href="/">
          <img src={LogoTextSvg} alt={title} width="90px" height="39px" />
        </a>
      </Wrapper>
    ) : (
      <H tag="h2" size="XS" margin={['XS', 0]} marginFromM={[0, 0, 'S']}>
        {title}
      </H>
    )}
  </StyledFooterSectionTitle>
)

FooterSectionTitle.propTypes = {
  logoTitle: PropTypes.bool,
  title: PropTypes.string.isRequired,
  hideTitleOnS: PropTypes.bool,
  hideDividerOnS: PropTypes.bool,
  spanFromM: PropTypes.number.isRequired,
  spanFromL: PropTypes.number.isRequired,
}

FooterSectionTitle.defaultProps = {
  logoTitle: false,
  hideTitleOnS: false,
  hideDividerOnS: false,
}

export default FooterSectionTitle
