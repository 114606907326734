import React from 'react'

import { FAREWILL_WILLS_URL } from 'config'
import { filterByKey } from 'lib/object/helpers'
import { getSessionStorage } from 'lib/storage/sessionStorage'
import { trackEvent } from 'lib/tracking'

import { getQueryString } from './helpers'

type ProductLinkOptions = {
  preselectedService?: 'online' | 'telephone'
  partnerCampaignReferral: boolean
  telephoneWillsEnabled: boolean
}

type ProductLinkProps = ProductLinkOptions & {
  className: string
  to: string
  children: React.ReactNode
}

const handleClick = ({
  event,
  preselectedService,
  partnerCampaignReferral,
  telephoneWillsEnabled,
}: ProductLinkOptions & {
  event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
}) => {
  const target = event.target as HTMLAnchorElement

  trackEvent(`Marketing link to ${target.href}`, 'click')

  const storageParams = getSessionStorage<{ code: string }>('urlParams')

  const queryParameters = getQueryString({
    preselectedService,
    partnerCampaignReferral,
    telephoneWillsEnabled,
    code: storageParams?.code,
  })
  target.setAttribute('href', target.href + queryParameters)
}

const ProductLink = ({
  className,
  to = '/',
  children,
  preselectedService,
  partnerCampaignReferral = false,
  telephoneWillsEnabled = false,
  ...props
}: ProductLinkProps): React.ReactElement => (
  <a
    className={className}
    href={`${FAREWILL_WILLS_URL}${to}`}
    onClick={(event) =>
      handleClick({
        event,
        preselectedService,
        partnerCampaignReferral,
        telephoneWillsEnabled,
      })
    }
    // Forward data attributes to underlying DOM element. Used for event
    // tracking (`data-track-event="click"`). See plugins/splitio-plugin/gatsby-browser.tsx
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...filterByKey(props, (key) => key.startsWith('data-'))}
  >
    {children}
  </a>
)

export default ProductLink
