/**
 * Converts a string into a UK-formatted phone number
 *
 * @param {*string} str The string to convert
 */
export default (inputTelephoneNumber: string): string => {
  const telephoneNumber = inputTelephoneNumber.replace(/[\s-]/g, '')
  const firstPart = telephoneNumber.substr(0, 4)
  const secondPart = telephoneNumber.substr(4, 3)
  const thirdPart = telephoneNumber.substr(7, 4)
  return `${firstPart} ${secondPart} ${thirdPart}`
}

// This method assumes the inputTelephoneNumber is already a valid UK number
// as it is only used after form validation has occurred (lib/form/validation.ts)
export const formatUkCountryCodePhoneNumber = (
  inputTelephoneNumber: string
): string => {
  const telephoneNumber = inputTelephoneNumber.replace(/[\s-]/g, '')

  if (telephoneNumber.startsWith('+44')) {
    return telephoneNumber
  }

  if (telephoneNumber.startsWith('0')) {
    const slicedNumber = telephoneNumber.slice(1, telephoneNumber.length)
    return `+44${slicedNumber}`
  }

  return `+44${telephoneNumber}`
}
