import React from 'react'
import styled from 'styled-components'

import { Grid, BackgroundImageWrapper } from '@farewill/ui'
import { screenMax } from '@farewill/ui/helpers/responsive'

const FooterIllustrationWrapper = styled(Grid.Item)`
  align-self: end;

  ${screenMax.m`
    display: none;
  `}
`

const Illustration = styled(BackgroundImageWrapper)`
  width: 120%;
  height: 0;
  padding-bottom: 85%;
  margin: 0 -10%;
`

const FooterIllustration = () => (
  <FooterIllustrationWrapper spanFromL={3} spanRows={2}>
    <Illustration
      imagePath="illustrations/cat-privacy"
      imageWidth={320}
      cover
    />
  </FooterIllustrationWrapper>
)

export default FooterIllustration
