import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Wrapper } from '@farewill/ui'
import { BREAKPOINT } from '@farewill/ui/tokens'

import BreakpointSwitch from 'components/BreakpointSwitch'

import LogoTextSvg from 'assets/logo-text.svg'
import LogoIconSvg from 'assets/logo-icon.svg'

const StyledWrapper = styled(Wrapper)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 0;
`

const StyledHeader = styled(Wrapper)`
  ${({ $color }) => $color && `background-color: ${$color}`}
`

const HeaderMinimal = ({ color, hideFarewillLogo }) => {
  if (hideFarewillLogo) return <Wrapper />

  return (
    <StyledHeader $color={color} tag="header">
      <Wrapper container containerPaddingTop={0} containerPaddingBottom={0}>
        <StyledWrapper>
          <BreakpointSwitch
            breakAt={BREAKPOINT.S}
            aboveComponent={
              <img
                src={LogoTextSvg}
                alt="Farewill"
                width="90px"
                height="39px"
              />
            }
            belowComponent={
              <img
                src={LogoIconSvg}
                alt="Farewill"
                width="26px"
                height="39px"
              />
            }
          />
        </StyledWrapper>
      </Wrapper>
    </StyledHeader>
  )
}

HeaderMinimal.propTypes = {
  color: PropTypes.string,
  hideFarewillLogo: PropTypes.bool,
}

HeaderMinimal.defaultProps = {
  color: null,
  hideFarewillLogo: false,
}

export default HeaderMinimal
