import React from 'react'
import styled from 'styled-components'
import { Wrapper } from '@farewill/ui'
import { COLOR, GTR } from '@farewill/ui/tokens'

const StyledWrapper = styled(Wrapper)`
  align-items: center;
  background-color: ${({ $background }) => $background};
  color: ${({ $color }) => $color};
  display: flex;
  justify-content: center;
  padding: ${GTR.S};
`

export type NoticeBannerProps = {
  backgroundColor?: string
  children: React.ReactNode
  textColor?: string
}

const NoticeBanner = ({
  backgroundColor = COLOR.BLACK,
  children,
  textColor = COLOR.GREY.LIGHT,
}: NoticeBannerProps): React.ReactElement => (
  <StyledWrapper $background={backgroundColor} $color={textColor}>
    {children}
  </StyledWrapper>
)

export default NoticeBanner
