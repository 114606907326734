import React, { ReactElement } from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { Wrapper } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'
import { screenMax } from '@farewill/ui/helpers/responsive'

import PATHS from 'paths'
import {
  LIVE_PRODUCTS,
  PRODUCTS_MAP,
  PRODUCTS_VIEWS_FOLDERS_MAPPING,
} from 'lib/products/constants'
import LogoTextSvg from 'assets/logo-text.svg'

import TelephoneNumber from 'components/TelephoneNumber'

const StyledWrapper = styled(Wrapper)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 0;
`

const StyledTelephoneNumber = styled(TelephoneNumber)`
  ${screenMax.s`
    display: none
  `}
`

const StyledHeader = styled(Wrapper)<{
  $color?: string
  $hideFarewillLogo?: boolean
}>`
  border-bottom: 1px solid ${COLOR.GREY.LIGHT};

  ${({ $color }) => css`
    ${$color && `background-color: ${$color};`}
    ${$color !== COLOR.WHITE && `border-bottom-color: ${$color};`}
  `}

  ${({ $hideFarewillLogo }) =>
    $hideFarewillLogo &&
    screenMax.s`
    display: none;
  `}
`

const logo = <img src={LogoTextSvg} width="90px" height="39px" alt="Farewill" />

export type HeaderCompactProps = {
  selectedProduct: (typeof LIVE_PRODUCTS)[number]
  color?: string
  telephoneNumber?: string
  hideFarewillLogo?: boolean
  hideTelephoneNumber?: boolean
  headerFarewillLogoLink?: string
}

const HeaderCompact = ({
  color,
  selectedProduct,
  telephoneNumber,
  hideFarewillLogo,
  hideTelephoneNumber,
  headerFarewillLogoLink,
}: HeaderCompactProps): ReactElement => {
  const showHeader = !(hideFarewillLogo && hideTelephoneNumber)
  const farewillLogoPath: string | undefined =
    headerFarewillLogoLink ||
    PATHS[PRODUCTS_VIEWS_FOLDERS_MAPPING[selectedProduct]]?.OVERVIEW

  return showHeader ? (
    <StyledHeader
      $color={color}
      tag="header"
      $hideFarewillLogo={hideFarewillLogo}
    >
      <Wrapper container containerPaddingTop={0} containerPaddingBottom={0}>
        <StyledWrapper $color={color}>
          <Wrapper>
            {!hideFarewillLogo &&
              (farewillLogoPath ? (
                <Link to={farewillLogoPath}>{logo}</Link>
              ) : (
                logo
              ))}
          </Wrapper>
          {!hideTelephoneNumber && (
            <StyledTelephoneNumber
              telephoneNumber={
                telephoneNumber ||
                PRODUCTS_MAP[selectedProduct]?.telephoneNumber
              }
            />
          )}
        </StyledWrapper>
      </Wrapper>
    </StyledHeader>
  ) : (
    <></>
  )
}

export default HeaderCompact
