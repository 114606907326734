import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { Wrapper, Divider } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'

import AllProductsSection from './AllProductsSection'
import OtherLinksSection from './OtherLinksSection'

const StyledMenu = styled(Wrapper)`
  background-color: ${COLOR.WHITE};
  color: ${COLOR.BLACK};
  border-bottom: solid 1px ${COLOR.GREY.LIGHT};
  max-height: 100%;
  overflow: auto;
`

type MenuProps = {
  className?: string
  showLoginLink?: boolean
}

const Menu = ({ className, showLoginLink }: MenuProps): ReactElement => (
  <StyledMenu className={className}>
    <>
      <Wrapper container containerPaddingTop="M" containerPaddingBottom="M">
        <AllProductsSection />
      </Wrapper>

      <Divider container />
    </>

    <Wrapper container containerPaddingTop="M" containerPaddingBottom="M">
      <OtherLinksSection showLoginLink={showLoginLink} />
    </Wrapper>
  </StyledMenu>
)

export default Menu
