import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useLocation } from '@reach/router'

import { ROOT_URL, TRUSTPILOT_RATING_OUT_OF_5, RELEASE_ENV } from 'config'
import { removeTrailingSlash } from 'lib/formatting/urls'
import { SITEMAP_EXCEPTIONS } from 'lib/gatsby/config/sitemap'
import setGlobalIndexingDirective from './setGlobalIndexingDirective'

const SEO = ({ title, description, shareImage, meta, canonicalPath }) => {
  const { pathname } = useLocation()
  /**
   * Paths on localhost have trailing slash, this means
   * that if you want to debug isSitemapException on localhost, you
   * have to temporarily add slash at the end of all exceptions
   * in lib/gatsby/config/sitemap.js
   * */
  const isSitemapException = SITEMAP_EXCEPTIONS.includes(pathname)

  const removeRobotsForExceptions = (metaTags) => {
    if (isSitemapException) {
      return metaTags.filter((metaTag) => metaTag.name !== 'robots')
    }

    return metaTags
  }

  const url = pathname && removeTrailingSlash(`${ROOT_URL}${pathname}`)
  const defaultMetaTags = [
    {
      name: 'description',
      content: description,
    },
    {
      property: 'og:title',
      content: title,
    },
    {
      property: 'og:description',
      content: description,
    },
    {
      property: 'og:url',
      content: url,
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      property: 'og:image',
      content: shareImage,
    },
    {
      name: 'twitter:card',
      content: 'summary',
    },
    {
      name: 'twitter:site',
      content: '@farewill',
    },
    {
      name: 'twitter:title',
      content: title,
    },
    {
      name: 'twitter:description',
      content: description,
    },
    {
      property: 'twitter:image',
      content: shareImage,
    },
  ]

  const canonicalTag = {
    rel: 'canonical',
    href:
      canonicalPath && !isSitemapException
        ? `${ROOT_URL}${canonicalPath}`
        : url,
  }

  return (
    <Helmet
      title={title}
      meta={setGlobalIndexingDirective(
        removeRobotsForExceptions(defaultMetaTags.concat(meta)),
        RELEASE_ENV
      )}
      link={[
        {
          rel: 'shortcut icon',
          href: '/icons/favicon.png',
        },
      ].concat(canonicalPath || pathname ? canonicalTag : [])}
    />
  )
}

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  shareImage: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  canonicalPath: PropTypes.string,
}

SEO.defaultProps = {
  canonicalPath: '',
  title: 'Protect Your Family Today | The Nation’s No. 1 Will Specialists',
  description: `Write your will online in 15 minutes and get help from our specialists 7 days a week. Rated ${TRUSTPILOT_RATING_OUT_OF_5} on Trustpilot by thousands.`,
  shareImage: `${ROOT_URL}/logos/share.jpg`,
  meta: [],
}

export default SEO
